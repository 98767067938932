@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400;600&display=swap');

/* Reset */
*,*::before,*::after
{
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
}

/* Varibles */
:root
{
  --body-black: #141718;
  --main-container-black:  #1E1F23;
  --sidebar-black: #141718;
  --purple-shade: #6846EC;
  --dark-purple-shade: #4c34aa;
  --light-shade-grey: #28292D;
  --lightest-shade-grey: #60626D;
  --green-shade: #29EBC8;
  --call-to-action-red: #F14648;
  --main-font-size: 16px;
  --font-grey: #fff;
  --black: #000;
  --meta-green: #6c3;
  --meta-green-light: rgb(159, 252, 112);
  --add: #19671B;
  --minus:#992E30;
  --header: rgb(196, 195, 195);
  --completed: #24AB26;
  --playing: #F7CA65;
}

/* Basic css set up */

img
{
  width: 100%;
}


body
{
  background-color: var(--body-black);
  color: var(--font-grey);
  font-family: 'Orbitron', sans-serif;
}

.main-container
{
  max-width: 1200px;
  margin: 0 auto;
  background-color: var(--main-container-black);
}


/* Login */

.error-tab
{
  background-color: var(--minus);
  padding: 1rem;
  border-radius: 10px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  position: absolute;
}

.login-container
{
  background-color: var(--purple-shade);
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-flow: column;
  max-width: 80%;
  margin: 5rem auto;
  border-radius: 10px;
}

.login-bg
{
  min-height: 80vh;
}

.login-form
{
  max-width: 70%;
  max-height: 500px;
}

.login-container form
{
  padding: 2rem;
  max-height: 500px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: flex-start;
  margin: 0 auto;
}

.login-form-button
{
  padding: 1rem 2rem;
  background-color: var(--light-shade-grey);
  color: var(--font-grey);
  border-radius: 10px;
}

.login-form-button:hover
{
  background-color: var(--call-to-action-red);
  color: var(--black);
}

.login-form-label
{
  padding: 0.2rem;
  margin-bottom: 0.2rem;
}

.login-form-input
{
 padding: 0.6rem;
 border: 1px solid var(--black);
 border-radius: 10px;
 margin-bottom: 1rem;
 width: 500px;
}


/* Header section */

.nav
{
  max-width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: var(--light-shade-grey);
  padding: 1.5rem 2rem;
  border-radius: 0 0 10px 10px;

}

.offCanvasButton
{
  background-color: var(--purple-shade);
  color: var(--font-grey);
  padding: 0.8rem;
  font-size: 1.5rem;
  border-radius: 10px;
}

.logo-nav
{
  display: flex;
  flex-basis: 15%;
  justify-content: center;
  align-items: center;
}

.sign-in
{
  cursor: pointer;
  padding: 0.8rem 2rem;
  font-size: 1.2rem;
  font-weight: bold;
  border-radius: 10px;
}

.sign-in:hover
{
  background-color: var(--call-to-action-red);
  color: var(--black);
}
.logo
{
  cursor: pointer;
}

.logo:hover
{
  color: var(--call-to-action-red);
}

.offCanvas
{
  display: none;
}

.nav-user
{
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-user p
{
  font-weight: bold;

}



/* Footer section */

footer
{
  position: relative;
  bottom: 0;
  background-color: var(--light-shade-grey);
  padding: 1.4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px 10px 0 0;
}

.footer-links-container
{
  display: flex;
  justify-content: space-around;
  flex-flow: row;
}

.rawg
{
  background-color: var(--call-to-action-red);
  padding: 0.4rem;
  border-radius: 10px;
  text-align: center;
}

.footer-sections a
{
  color: #fff;
  text-decoration: none;
  display: block;
}

.footer-links-container img
{
  padding-right: 0.4rem;
}

.footer-links-container a:hover
{
  color: var(--call-to-action-red);
  cursor: pointer;
}

.footer-links
{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
}

.footer-sections
{
  flex-basis: auto;
}


/* Sidebar Nav */

.sidebar
{
  display: flex;
  flex-flow: column;
  padding: 0.2rem;
  padding-right: 0 ;
  align-items: flex-start;
  background-color: var(--purple-shade);
  border-radius: 10px;
}

.sidebar ul
{
  list-style-type: none;
  padding: 0;
  margin: 0;
  margin-top: 2rem;
  width: 100%;
}

.sidebar ul li
{
  padding: 1rem;
  font-size: 1.4rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid var(--dark-purple-shade);
  border-radius: 10px 0 0 10px;
  
}

.sidebar ul li:hover
{
  background-color: var(--call-to-action-red);
}

.sidebar a
{
  text-decoration: none;
  color: var(--font-grey);
  display: block;
}

.active-link
{
  background-color: var(--call-to-action-red);
  border-radius: 10px 0 0 10px;
}

.user-links
{
  background-color: #4c34aa;
}

.user-links:hover
{
  cursor: pointer;
}

.nav-icon
{
  background-color: var(--dark-purple-shade);
  padding: 0.6rem 1rem;
  border-radius: 10px;
}

.nav ul
{
  list-style: none;
  display: flex;
  flex-flow: row;
  flex-basis: 20%;
  align-items: center;
  justify-content: flex-end;
}

.nav ul li a
{
  padding: 0.6rem 1rem ;
  display: block;
  border-radius: 10px;
  font-weight: bold;
  text-decoration: none;
  margin-inline: 0.5rem;
}

.offCanvas-nav
{
  z-index: 10;
  width: 100%;
  display: none;
}


.offCanvas-active
{
  left: 0;
  position: fixed;
}

.offCanvas-nav ul li
{
  margin-bottom: 0.5rem;
}



/*   Main and User card section   */
.main-container-content
{
  display: grid;
  grid-template-columns: 1fr 4fr;
  padding-top: 0.5rem;
  position: relative;
  min-height: 90vh;
}

.games-grid
{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-content: start;
  row-gap: 1rem;
  column-gap: 0.8rem;
  padding: 1rem;
}

.pagination-button-section
{
  display: flex;
  justify-content: center;
  padding: 0.5rem;
  padding-bottom: 2rem;
}

.pagination-button
{
  background-color: var(--purple-shade);
  color: var(--font-grey);
  padding: 0.6rem 1rem;
  font-size: 1.2rem;
  border-radius: 10px;
  cursor: pointer;
}
.pagination-button:hover
{
  background-color: var(--call-to-action-red);
}

.carousel-container
{
  margin-top: 1rem;
  width: 100%;
  padding-block: 1rem;
  background-color: var(--light-shade-grey);
  border-radius: 20px;
}

.test-slide-container
{
  padding: 1rem;
}

.test-slide
{
  border-radius: 10px;
  border: 5px solid var(--light-shade-grey);
  width: 100%;
  cursor: pointer;
  position: relative;
}
.test-slide:hover
{
  border: 5px solid var(--purple-shade);
  position: relative;
}

.test-slide img
{
  border-radius: 10px;
  min-height: 100%;
}

.test-slide-grad
{
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: linear-gradient(180deg, rgba(0,0,0,0.1) 40%, rgba(0,0,0,0.85) 70%); 
  border-radius: 10px;
}

.test-slide-text
{
  position: absolute;
  bottom: 1rem;
  left: 50%;
  transform: translate(-50%,0);
  font-size: 1.2rem;
  font-weight: bold;
  width: 80%;
  text-align: center;
}

.test-slide-score
{
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 10px;
  padding: 0.6rem;
  font-weight: 600;
  background-color: var(--body-black);
  border: 3px solid var(--meta-green);
  border-right: 3px solid var(--meta-green-light);
  border-top: 3px solid var(--meta-green-light);
}

/* Cards on main page */

.game-card
{
  position: relative;
  color: var(--font-grey);
  font-size: 1rem;
  font-family: 'Orbitron', sans-serif;
  height: 300px;
  max-width: 300px;
  border-radius: 20px 20px 10px 10px;
  background-color: var(--light-shade-grey);
  display: grid;
  grid-template-areas: 
  "card-image card-image card-image card-image"
  "game-title game-title game-title game-title"
  "game-title game-title game-title game-title"
  "game-title game-title game-title game-title";
  cursor: pointer;
}

.game-card:hover
{
  border: 2px solid var(--purple-shade);
  border-bottom: 5px solid var(--purple-shade);
  position: relative;
  top: -5px;
}

.game-card-image
{
  grid-area: card-image;
  overflow-y: hidden;
  height: 170px;
  border-radius: 20px 20px 0 0;
}

.img-in-card
{
  min-height: 170px;
}

.game-card-consoles
{
  grid-area: consoles;
  background-color: var(--purple-shade);
  position: absolute;
  max-width: 15%;
  height: inherit;
  top: 0;
  right: 0;
  border-radius: 0 20px 0 0;
  padding: 0.5rem;
}

.game-card-consoles ul
{
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 0.2rem;
}


.game-card-title
{
 
  grid-area: game-title;
  display: grid;
  padding: 1rem;
  align-items: center;
  grid-template-areas: 
  "game-name game-name game-name game-name"
  ". . . ."
  "release release . .";
}

.game-card-name
{
  color: rgb(240, 240, 240);
  grid-area: game-name;
  text-align: center;
  padding-bottom: 0.2rem;
  border-bottom: 1px solid var(--purple-shade);
  font-size: 1.2rem;
  font-weight: bold;
  min-height: 55px;
}
.game-card-release
{
  grid-area: release ;
  padding-block: 0.8rem;
  text-align: left;
}
.game-card-score
{
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 10px;
  padding: 0.6rem;
  font-weight: 600;
  background-color: var(--body-black);
  border: 3px solid var(--meta-green);
  border-right: 3px solid var(--meta-green-light);
  border-top: 3px solid var(--meta-green-light);
}

.game-card-rating
{
  position: absolute;
  bottom: 15px;
  right: 10px;
  padding: 0.5rem;
  background-color: var(--dark-purple-shade);
  border-radius: 10px;
  z-index: 5;
  min-width: 60px;
}

.game-card-gamestatus
{
  position: absolute;
  width: 100%;
  border-radius: 0 0 10px 10px;
  bottom: 0;
  z-index: 2;
}

.game-card:hover > .game-card-gamestatus
{
  display: none;
}


/* Filter section */

.filter
{
  margin-top: 0.5rem;
  max-width: 100%;
  background-color: var(--light-shade-grey);
  padding: 1.2rem;
  border-radius: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.filter-input
{
  padding: 0.4rem 1rem;
  background-color: var(--main-container-black);
  border-radius: 10px 0 0 10px;
  border: 1px solid var(--dark-purple-shade);
  color: var(--font-grey);
}
.filter-input-button
{
  background-color: var(--purple-shade);
  color: var(--font-grey);
  padding: 0.4rem;
  border: 1px solid var(--dark-purple-shade);
  border-radius: 0 10px 10px 0;
}

 /* Game Card Information */



 .games-information-card
 {
   background-color: var(--light-shade-grey);
   width: 75%;
   margin: 1rem auto;
   border-radius: 10px;
   position: relative;
 }
 
 .card-info-image-section
 {
   padding: 1.4rem;
 }
 
 .card-info-image-section img
 {
   border-radius: 10px;
 }
 
 .game-information-card-text
 {
   display: grid;
   padding: 1rem;
   gap: 1rem;
   grid-template-areas: 
   "game-information-title game-information-title game-information-title game-information-title"
   "game-information-title game-information-title game-information-title game-information-title"
   "game-information-form game-information-form game-information-form game-information-button"
   "game-information-release game-information-release game-information-score game-information-rating"
   "game-information-genre game-information-genre game-information-platforms game-information-platforms"
   "game-information-dev game-information-dev game-information-pub game-information-pub "
   "game-information-details game-information-details game-information-details game-information-details " ;
 }
 
 .game-information-card-text-title
 {
   grid-area: game-information-title;
   text-align: center;
   font-size: 1.6rem;
 }
 .game-information-card-text-button
 {
   grid-area: game-information-button;
   padding: 1rem;
   display: flex;
   justify-content: center;
   align-items: center;
 }
 .game-information-card-text-score
 {
   grid-area: game-information-score;
 }
 .game-information-card-text-release
 {
   grid-area: game-information-release;
 }
 .game-information-card-text-genre
 {
   grid-area: game-information-genre;
 }
 .game-information-card-text-platforms
 {
   grid-area: game-information-platforms;
 }
 .game-information-card-text-details
 {
   grid-area: game-information-details;
 }
 .game-information-card-text-dev
 {
   grid-area: game-information-dev;
 }
 .game-information-card-text-pub
 {
   grid-area: game-information-pub;
 }
 .game-information-card-text-rating
 {
   grid-area: game-information-rating;
 }
 .game-information-card-text-form
 {
   grid-area: game-information-form;
   display: flex;
   justify-content: center;
   align-items: center;
 }

 .game-info-form
 {
  padding: 1rem;
  display: flex;
  width: 100%;
  align-items: center;
 }

 .game-info-form-lable
 {
    padding: 0.2rem;
 }

 .game-info-form-input
 {
  padding: 0.4rem;
  border-radius: 10px;
  border: none;
  background-color: var(--dark-purple-shade);
  color: var(--font-grey);
  margin: 0.5rem;
 }

 .game-info-form-button
 {
  padding:  0.4rem;
  max-height: 40px;
  border-radius: 10px;
  color: #fff;
  font-weight: bold;
  background-color: var(--call-to-action-red);
 }

 .firebase-button
{
  padding:  0.6rem;
  border-radius: 10px;
  color: #fff;
  font-weight: bold;
}
 
 [data-bg-colour = "red"]
 {
  background-color: var(--call-to-action-red);
  color: var(--font-grey);
 }
 
 [data-bg-colour = "purple"]
 {
  background-color: var(--purple-shade);
  color: var(--font-grey);
 }
 
 .game-header
 {
   padding: 0.6rem 0.4rem;
   border-bottom: 3px solid var(--dark-purple-shade);
   margin-bottom: 0.6rem;
   text-align: center;
   font-weight: bold;
   color: var(--header);
 }
 
 .game-text-align
 {
   text-align: center;
 }
 
 .mapped-text
 {
   display: inline-block;
   padding: 0.2rem 0.5rem;
   margin: 0.2rem;
   border-radius: 10px;
   border: 1px solid var(--font-grey);
 }

 [data-colour="Plus"] {
  background-color: var(--add);
}

[data-colour="Minus"] {
  background-color: var(--minus);
}

.go-back-button
{
  cursor: pointer;
  position: absolute;
  top: 1.4rem;
  left: 1.4rem;
  background-color: var(--purple-shade);
  border-radius: 10px;
  color: var(--font-grey);
  padding: 0.6rem 1.2rem;
  border: 2px solid var(--dark-purple-shade);
}

/* Profile */

.profile-card
{
  background-color: var(--light-shade-grey);
  max-width: 60%;
  margin: 100px auto;
  border-radius: 10px;
  padding: 1rem;
}

.profile-card-img
{
  max-width: 40%;
  margin: 1rem auto;
  background-color: var(--dark-purple-shade);
  border-radius: 10px;
  text-align: center;
}
.profile-card-img img
{
  max-width: 60%;
  max-height: 60%;
}

.profile-card-section
{
  text-align: center;
  font-size: 1.6rem;
  padding: 1rem;
}

.not-login-button-section
{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
}

.not-login-button
{
  padding: 1rem 2rem;
  background-color: var(--purple-shade);
  color: var(--font-grey);
  border-radius: 10px;
  font-size: 1.6rem;
  margin-top: 1rem;
  cursor: pointer;
}

.not-login-button:hover
{
  background-color: var(--call-to-action-red);
}

@keyframes myAnim {
	0% {
		opacity: 0;
		transform: translateY(-250px);
	}

  50%
  {
    opacity: 0.7;
  }

	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

.user-stats
{
  padding: 1rem;
  background-color: var(--light-shade-grey);
  margin-top: 0.5rem;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
}

.sort select
{
  border: none;
  border-radius: 10px 0 0 10px;
  padding: 0.5rem;
  background-color: var(--purple-shade);
  color: var(--font-grey);
}

.sort input
{
  border: none;
  border-radius: 0 10px 10px 0;
  padding: 0.5rem;
  background-color: var(--dark-purple-shade);
  color: var(--font-grey);
}

[data ="Playing"]
{
 border-bottom: 4px solid var(--playing);
}

[data ="Completed"]
{
 border-bottom: 4px solid var(--completed);
}

.message-board
{
  width: 90%;
  padding: 2rem;
  border-radius: 10px;
  margin: 2rem auto;
  background-color: var(--dark-purple-shade);
  height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.warning
{
  background-color: var(--call-to-action-red);
}





/* Responsive design still working on this section */
/* Laptops  */
@media only screen and (max-width: 1439px)
{
  .sidebar ul li
  {
    font-size: 1rem;
  }

  .game-card-name
  {
    font-size: 1rem;
  }

  .logo-nav
  {
    flex-basis: 20%;
  }

  .game-card-consoles
  {
    max-width: 18%;
  }

}


/* Tablets */
@media only screen and (max-width: 1023px)
{

  .main-container-content
  {
    display: grid;
    grid-template-columns: 1fr;
  }

  .offCanvas
  {
    display: block;
    flex-basis: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .mobile-hide
  {
    display: none;
  }

  .offCanvas-nav
  {
    display: block;
    animation: myAnim 0.5s ease-in-out 0s 1 normal both;
    position: absolute;
  }

  .logo-nav
  {
    flex-basis: 25%;
  }

  .login-container
  {
    padding: 0;
  }

  .login-form-input
  {
    width: 400px;
  }

  .carousel-container
  {
    display: none;
  }

  .game-info-form-button
  {
    margin-top: 1rem;
  }

  .games-information-card
 {
   width: 80%;
 }


}

/* Phones */
@media only screen and (min-width: 320px) and (max-width: 767px)
{

  .login-container form
  {
    margin: 0;
  }

  .login-form-input
  {
    width: 250px;
  }

  .logo-nav
  {
    flex-basis: 30%;
  }

  .sign-in
  {
    font-size: 0.8rem;
  }

  .logo
  {
    font-size: 1rem;
  }



.games-grid
{
  grid-template-columns: 1fr;
  justify-items: center;
}

.game-card
{
  width: 300px;
}

.games-information-card
 {
   width: 98%;
 }

.game-information-card-text
{
  grid-template-areas: 
  "game-information-title game-information-title"
  "game-information-title game-information-title"
  "game-information-form game-information-form"
  "game-information-button game-information-button"
  "game-information-release game-information-release"
  "game-information-rating game-information-score"
  "game-information-platforms game-information-genre"
  "game-information-dev game-information-dev"
  "game-information-pub game-information-pub"
  "game-information-details game-information-details" ;
}

.game-info-form
{
  flex-flow: column;
}

.game-info-form-lable
{
  font-size: 0.9rem;
}

.game-information-card-text-button
{
  display: flex;
  justify-content: center;
}


.mapped-text
{
  font-size: 0.9rem;
}

.game-header
{
  font-size: 0.9rem;
}

.profile-card
{
  max-width: 80%;
}

.profile-card-img
{
  max-width: 60%;
}

.profile-card-section
{
  font-size: 1.2rem;
}


footer
{
  position: relative;
  gap: 3rem;
  padding-top: 3rem ;
  flex-flow: column;
 
}


}






